import { Observable } from 'rxjs';
import { TemplateInGrid } from './template-in-grid.model';
import { TemplateDetail } from './template-detail.model';
import { BladeConfig } from 'src/app/core/blade/blade-config';
import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/http/http.service';

@Injectable({
    providedIn: 'root'
})
export class TemplateService {
    constructor(private http: HttpService) {}

    public getReminderOverview(department: string, filter: string): Observable<TemplateInGrid[]> {
        return this.http.getReminderApi<TemplateInGrid[]>(department, 'reminders/overview?filter=' + filter);
    }

    public getReminderDetail(
        departmentId: string,
        templateName: string,
        culture: string,
        extension: string
    ): Observable<TemplateDetail> {
        return this.http.getReminderApiWithLanguage(
            departmentId,
            `reminders/${templateName}?extension=${extension}`,
            culture
        );
    }

    public uploadAndReplaceReminder(departmentId: string, templateName: string, culture: string, file: File) {
        const body = this.getReminderBody(templateName, culture, file);

        return this.http.postReminderApi<string>(departmentId, `reminders/replace`, body);
    }

    public createReminder(departmentId: string, templateName: string, culture: string, file: File) {
        const body = this.getReminderBody(templateName, culture, file);

        return this.http.postReminderApi<string>(departmentId, `reminders`, body);
    }

    public renameReminder(departmentId: string, oldReminderName: string, newReminderName: string) {
        return this.http.postReminderApi(departmentId, 'reminders/rename', {
            oldReminderName,
            newReminderName
        });
    }

    public getBladeConfigDetail(name: string, data: any): BladeConfig {
        return new BladeConfig(4, name, data);
    }

    private getReminderBody(templateName: string, culture: string, file: File): FormData {
        const formData = new FormData();

        formData.append('templateName', templateName);
        formData.append('culture', culture);
        formData.append('file', file);

        return formData;
    }
}
