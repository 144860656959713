import { Component, OnInit } from '@angular/core';
import { debounceTime, tap } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { Alert } from './alert.model';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
    public alert$ = new Subject<Alert | null>();

    constructor(private service: AlertService) {}

    public ngOnInit(): void {
        this.service.alert$
            .pipe(
                tap((alert) => this.alert$.next(alert)),
                debounceTime(5000),
                tap(() => this.alert$.next(null))
            )
            .subscribe();
    }
}
