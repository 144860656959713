import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Alert } from './alert.model';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    private readonly _alert$ = new Subject<Alert>();

    public alert$ = this._alert$.asObservable();

    public showError(message: string): void {
        this._alert$.next({ type: 'danger', message });
    }

    public showSuccess(message: string): void {
        this._alert$.next({ type: 'success', message });
    }
}
