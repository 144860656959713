import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-file-dropzone',
    templateUrl: 'file-dropzone.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: FileDropzoneComponent
        }
    ],
    styleUrls: ['file-dropzone.component.scss']
})
export class FileDropzoneComponent implements OnChanges, ControlValueAccessor {
    private _touched: boolean;

    public currentFile: File;
    public formattedFileTypes: string;
    public isInsideDropZone: boolean;

    @Input()
    public downloadLink: string;

    @Input()
    public placeholder: string;

    @Input()
    public allowedFileExtensions: string[];

    @Input()
    public isValid: boolean = true;

    @ViewChild('fileInput', { static: true })
    public fileInput: ElementRef<HTMLInputElement>;

    public onChange = (_file: File) => {};

    public onTouched = () => {};

    public ngOnChanges(): void {
        this.formattedFileTypes = this.formatFileTypes(this.allowedFileExtensions);
    }

    public writeValue(file: File): void {
        this.currentFile = file;
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public onDropZoneEnter(): void {
        this.isInsideDropZone = true;
        console.log('isinside');
    }

    public onDropZoneLeave(): void {
        this.isInsideDropZone = false;
    }

    public onFileDropped(event: DragEvent): void {
        event.preventDefault();

        if (!(event.dataTransfer.items && event.dataTransfer.items.length === 1)) return;

        const item = event.dataTransfer.items[0];

        if (item.kind !== 'file') return;

        const file = item.getAsFile();
        this.onFileChanged(file);
    }

    public onFileSelected(event: Event): void {
        const element = event.target as HTMLInputElement;

        const file = element.files[0];

        if (!file) return;

        this.onFileChanged(file);
    }

    public onFileRemoved(): void {
        this.downloadLink = null;
        this.currentFile = null;
        this.onChange(null);
    }

    private onFileChanged(file: File): void {
        this.isInsideDropZone = false;
        this.markAsTouched();
        this.currentFile = file;
        this.onChange(this.currentFile);
    }

    private formatFileTypes(fileExtensions: string[]): string {
        return fileExtensions.map((e) => e.substring(1).toUpperCase()).join(', ');
    }

    private markAsTouched(): void {
        if (!this._touched) {
            this.onTouched();
            this._touched = true;
        }
    }
}
