import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const fileExtensionValidator = (allowedFileExtensions: string[]): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
        const file = control.value as File;

        if (!file) return null;

        const fileExtension = getFileExtension(file.name);

        return allowedFileExtensions.includes(`.${fileExtension}`) ? null : { invalidFileExtension: fileExtension };
    };
};

const getFileExtension = (fileName: string): string => {
    const parts = fileName.split('.');
    return parts.length > 1 ? parts.pop() : '';
};
