import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BladeConfig } from 'src/app/core/blade/blade-config';
import { BladeRef } from 'src/app/core/blade/blade-ref';
import { TranslationService } from 'src/app/shared/translation/translation.service';
import { LoaderService } from 'src/app/core/loader/loader.service';
import { TemplateDetail } from 'src/app/templates/shared/template-detail.model';
import { TemplateInGrid } from '../shared/template-in-grid.model';
import { TemplateService } from '../shared/template.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { concat, EMPTY, Observable } from 'rxjs';
import { fileExtensionValidator } from '../validators/file-extension.validator';

interface TemplateFormValue {
    file: File;
    name: string;
    culture: string;
}

@Component({
    selector: 'app-templates-detail',
    templateUrl: './templates-detail.component.html',
    styleUrls: ['./templates-detail.component.scss']
})
export class TemplatesDetailComponent implements OnInit {
    departmentId: string;
    loader = this.loaderService.empty;
    errorMessage: string;
    template: TemplateDetail;
    formGroup: FormGroup;
    showValidation: boolean;
    availableCultures: string[] = ['en-US', 'nl-BE', 'fr-FR', 'nl-NL', 'fr-BE'].sort();
    // <name>.<culture>.repx
    fileNameRegex: RegExp = new RegExp(`^(.*?)\\.(${this.availableCultures.join('|')})\\.repx$`);
    allowedFileExtensions: string[] = ['.repx'];
    originalFormValue: TemplateFormValue;

    constructor(
        private config: BladeConfig,
        private bladeRef: BladeRef,
        private service: TemplateService,
        private t: TranslationService,
        private loaderService: LoaderService,
        private fb: FormBuilder
    ) {}

    public ngOnInit(): void {
        this.initForm();
        this.loadTemplateDetail();
        this.listenToFormChanges();
    }

    public close(): void {
        this.bladeRef.dismiss();
    }

    public submitForm(): void {
        if (!this.formGroup.valid) {
            this.showValidation = true;

            return;
        }

        const observable = this.template ? this.updateTemplate() : this.createTemplate();

        this.loader = this.loaderService.saving;

        observable.subscribe({
            next: () => this.bladeRef.close(true),
            error: (error) => {
                this.loader = this.loaderService.empty;

                if (error.status === 409) {
                    this.errorMessage = this.t.get('TemplateAlreadyExists');
                } else if (error.error) {
                    this.errorMessage = error.error;
                } else {
                    this.errorMessage = this.t.get('GenericErrorLogged');
                }
            }
        });
    }

    private createTemplate(): Observable<string> {
        const { name, culture, file }: TemplateFormValue = this.formGroup.value;

        return this.service.createReminder(this.departmentId, name, culture, file);
    }

    private updateTemplate(): Observable<unknown> {
        const { file, name, culture } = this.formGroup.value;
        const { name: originalName, culture: originalCulture } = this.originalFormValue;
        const fileControl = this.formGroup.controls.file;
        const calls: Observable<unknown>[] = [];

        if (fileControl.dirty) {
            calls.push(this.service.uploadAndReplaceReminder(this.departmentId, originalName, originalCulture, file));
        }

        const hasNameOrCultureChanged = originalName !== name || originalCulture !== culture;
        if (hasNameOrCultureChanged) {
            const newFilename = `${name}.${culture}.repx`;
            calls.push(this.service.renameReminder(this.departmentId, this.template.Name, newFilename));
        }

        return calls.length ? concat(...calls) : EMPTY;
    }

    private listenToFormChanges(): void {
        this.formGroup.controls.file.valueChanges.subscribe((value: File) => {
            if (!this.template && value) {
                this.autofillFromFilename(value.name);
            }

            this.errorMessage = '';
        });
    }

    private initForm(): void {
        this.formGroup = this.fb.group({
            name: this.fb.control('', Validators.required),
            culture: this.fb.control('', Validators.required),
            file: this.fb.control(null, [Validators.required, fileExtensionValidator(this.allowedFileExtensions)])
        });
    }

    private loadTemplateDetail(): void {
        const data: TemplateInGrid = this.config.data.template;
        this.departmentId = this.config.data.departmentId;

        if (!data) return;

        this.loader = this.loaderService.loading;

        // Format: DocumentAbonnement_Bewonerskaart_Maxi_Knokke-Heist.nl-BE.rpt
        const templateName = data.Name.split('.');

        this.service
            .getReminderDetail(data.DepartmentId, templateName[0], templateName[1], templateName[2])
            .subscribe((t: TemplateDetail) => {
                this.template = t;
                this.loader = this.loaderService.empty;

                const splitName = t.Name.split('.');
                const formValue = {
                    name: splitName[0],
                    culture: splitName[1],
                    file: new File([], t.Name)
                };

                this.formGroup.patchValue(formValue);
                this.originalFormValue = this.formGroup.value;
            });
    }

    private autofillFromFilename(filename: string): void {
        const match = filename.match(this.fileNameRegex) || [];

        const [, name, culture] = match;

        this.formGroup.patchValue({
            name: this.formGroup.value.name || name || '',
            culture: this.formGroup.value.culture || culture || ''
        });
    }
}
