import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from './translation.service';

@Pipe({
    name: 'translate'
})
export class TranslatePipe implements PipeTransform {
    constructor(private readonly _translationService: TranslationService) {}

    public transform(translationKey: string): any {
        return this._translationService.get(translationKey);
    }
}
