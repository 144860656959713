import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EnvironmentService {
    applicationId = '28ad59c5-18df-4afc-a3ab-48c8fe9d7340';

    devUrlPrefix = 'q2c-';

    identityUrl = 'id';
    portalUrl = 'mportal';
    manApiUrl = 'manapi';
    complaintApiUrl = 'complaintapi';
    serveurFpsApiUrl = 'serveurfps';
    docApiUrl = 'docapi';
    staticDataApiUrl = 'staticapi';
    translationApiUrl = 'translationapi';
    reminderApiUrl = 'reminderapi';

    complaintApiVersion = 'v1';
    sfpsApiVersion = 'v1';
    staticDataApiVersion = 'v1';
    translationApiVersion = 'v1';
    reminderApiVersion = 'v1';

    init() {
        const host = window.location.origin;
        const split = host.split('.');

        if (split.indexOf('q2c') > -1) {
            // prod
            this.fillUrl(`.${split[split.length - 2]}.${split[split.length - 1]}`);
        } else if (split.indexOf('cs-dev') > -1) {
            // test or dev
            const subdomain = split[0].split('-');
            this.fillUrl(`-${subdomain[subdomain.length - 1]}.${split[split.length - 2]}.${split[split.length - 1]}`, true);
        } else {
            // localhost
            this.fillUrl('-dev1.cs-dev.io', true);
        }
    }

    private fillUrl(suffixUrl: string, isDev = false) {
        const suffix = suffixUrl.endsWith('/') ? suffixUrl : `${suffixUrl}/`;
        const buildUrl = (url: string) => `https://${isDev ? this.devUrlPrefix : ''}${url + suffix}`;

        this.identityUrl = buildUrl(this.identityUrl);
        this.portalUrl = buildUrl(this.portalUrl);
        this.manApiUrl = buildUrl(this.manApiUrl);
        this.complaintApiUrl = buildUrl(this.complaintApiUrl);
        this.serveurFpsApiUrl = buildUrl(this.serveurFpsApiUrl);
        this.docApiUrl = buildUrl(this.docApiUrl);
        this.staticDataApiUrl = buildUrl(this.staticDataApiUrl);
        this.translationApiUrl = buildUrl(this.translationApiUrl);
        this.reminderApiUrl = buildUrl(this.reminderApiUrl);
    }
}
