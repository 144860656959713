import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'templateName'
})
export class TemplateNamePipe implements PipeTransform {
    transform(value: string, substring: 'name' | 'culture' = 'name'): string {
        if (!value) return '';

        return value.split('.')[substring === 'name' ? 0 : 1];
    }
}
