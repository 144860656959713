import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { BladeConfig } from '../../core/blade/blade-config';
import { BladeService } from '../../core/blade/blade.service';
import { BladeRef } from '../../core/blade/blade-ref';
import { TemplateService } from 'src/app/templates/shared/template.service';
import { UserService } from '../../shared/user/user.service';
import { TemplatesDetailComponent } from 'src/app/templates/detail/templates-detail.component';
import { TemplateInGrid } from 'src/app/templates/shared/template-in-grid.model';

import * as $ from 'jquery';
import { TranslationService } from '../../shared/translation/translation.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { AlertService } from 'src/app/core/alert/alert.service';

@Component({
    selector: 'app-templates-overview',
    templateUrl: './templates-overview.component.html',
    styleUrls: ['./templates-overview.component.scss']
})
export class TemplatesOverviewComponent implements OnInit {
    public gridDataSource: CustomStore;
    public searchValue: string;
    public departmentId: string;

    @ViewChild('grid', { static: true }) grid: DxDataGridComponent;

    constructor(
        private config: BladeConfig,
        private blade: BladeService,
        private bladeRef: BladeRef,
        private authService: AuthService,
        private templateService: TemplateService,
        private alertService: AlertService,
        public userService: UserService,
        public t: TranslationService
    ) {}

    ngOnInit() {
        this.departmentId = this.authService.getDepartments()[0];
        this.gridDataSource = new CustomStore({
            load: () => {
                if (!this.departmentId) {
                    return [];
                }

                let cleanSearchValue = this.searchValue;
                if (!cleanSearchValue) {
                    cleanSearchValue = '';
                }
                return this.templateService
                    .getReminderOverview(this.departmentId, cleanSearchValue)
                    .toPromise()
                    .then((result: TemplateInGrid[]) => {
                        return {
                            data: result
                        };
                    });
            }
        });
    }

    close() {
        this.bladeRef.dismiss();
    }

    filterChanged() {
        // close all further blades
        this.blade.closeToIndex(this.config.index);
        this.refreshGrid();
    }

    refreshGrid() {
        // refresh the grid with the new data
        this.grid.instance.refresh();
    }

    // Used for calculating the height of the grid
    gridInitialized(e) {
        const filterHeight = $(e.element).parentsUntil('.content').parent().find('.filters').outerHeight(true);
        // calc --> 100% - height of the filter - margin top of the grid
        $(e.element).parentsUntil('.content').parent().find('.grid').css('height', `calc(100% - ${filterHeight}px)`);
    }

    onAfterValueChange(value) {
        this.searchValue = value;
        this.filterChanged();
    }

    addTemplateClicked() {
        this.openTemplateDetail();
    }

    gridValueChanged(e) {
        // == 0 when you clear a filter
        if (e.currentSelectedRowKeys.length === 0) {
            return;
        }

        const template: TemplateInGrid = e.currentSelectedRowKeys[0];

        this.openTemplateDetail(template);
    }

    openTemplateDetail(template?: TemplateInGrid) {
        const blade = this.blade.open(
            this.config.index,
            TemplatesDetailComponent,
            this.templateService.getBladeConfigDetail(this.t.get('NewTemplate'), {
                departmentId: template ? template.DepartmentId : this.departmentId,
                template
            })
        );

        blade.result.subscribe(
            (saved?: boolean) => {
                // deselect the current selection so that you can open the details of the previous selected instance
                this.deselectAll();
                // refresh the grid so the changes are seen
                this.filterChanged();

                if (saved) {
                    this.alertService.showSuccess(this.t.get('TemplateReplacedSuccess'));
                }
            },
            () => {
                // dismissed
                this.deselectAll();
            }
        );
    }

    deselectAll() {
        const keys = this.grid.instance.getSelectedRowKeys();
        this.grid.instance.deselectRows(keys);
    }
}
